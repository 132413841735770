<template>
  <div class="dashboard">
    <div class="header">
      <div class="left">
        <section>
          <h3>{{ timeSign }}，{{ username }}！</h3>
          <p>欢迎使用由英海瑞特科技研发系统，祝您工作愉快~</p>
        </section>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  mounted () {
    this.username = localStorage.getItem('username')
  },
  components: {

  },
  data () {
    return {
      username: null,
    }
  },
  methods: {},
  computed: {
    timeSign () {
      const timeSign = new Date().getHours()
      if (timeSign >= 6 && timeSign < 11) {
        return '早上好'
      } else if (timeSign >= 11 && timeSign < 13) {
        return '中午好'
      } else if (timeSign >= 13 && timeSign < 18) {
        return '下午好'
      } else {
        return '晚上好'
      }
    }
  },
  filters: {

  }
}
</script>

<style lang="scss" scoped>
.dashboard {
  height: 100%;

  overflow: hidden;
  // @include themify() {
  //   .header {
  //     height: 8vh;
  //     display: flex;
  //     flex-wrap: nowrap;
  //     justify-content: space-between;
  //     // margin-bottom: 20px;
  //     // align-items: center;
  //   }
  // }
}

span[class*="rank"] {
  display: inline-block;
  height: 2vh;
  width: 2vh;
  line-height: 2vh;
  text-align: center;
  border-radius: 50%;
}

.theme-light {
  .rank1 {
    background: #059370;
    color: #ffffff;
  }
  .rank2 {
    background: rgba($color: #059370, $alpha: 0.6);
    color: #ffffff;
  }
  .rank3 {
    background: rgba($color: #059370, $alpha: 0.4);
    color: #ffffff;
  }
  .rank4 {
    background: rgba($color: #f0f2f5, $alpha: 1);
    color: #666666;
  }
  .rank5 {
    background: rgba($color: #f0f2f5, $alpha: 1);
    color: #666666;
  }
  .item {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
